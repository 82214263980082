<template>
  <div>
    <b-card>
      <b-card-title>
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <div
            class="text-left order-2 mt-1 mt-md-0 order-md-1 w-100 w-md-auto"
          >
            <div class="d-flex w-100 w-md-auto align-items-center">
              <b-form-group class="mb-0 w-md-auto">
                <label class="text-sm-left">Filter</label>
                <b-input-group size="sm">
                  <b-form-input
                    @change="checkEmptySearchInput"
                    id="filterInput"
                    v-model="search"
                    type="search"
                    placeholder="Enter name"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="searchServices"
                      :disabled="!search"
                    >
                      Search
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="text-right order-1 order-md-2 w-100 w-md-auto">
            <b-button @click="initNewServiceAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>
              Add New</b-button
            >
          </div>
        </div>
      </b-card-title>
      <b-table
        @row-clicked="onRowClicked"
        :busy="isBusy"
        :items="services"
        hover
        :fields="fields"
        responsive
        show-empty
      >
        <template #empty="scope">
          <div class="text-center">
            <p>0 Service found!</p>
            <b-button @click="initNewServiceAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>

              Add New
            </b-button>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner variant="primary" class="align-middle"></b-spinner>
            <strong class="ml-1">Loading Services...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.name }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.designation }}
            </div>
          </div>
        </template>
      </b-table>

      <b-card-footer>
        <b-row>
          <b-col cols="3">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                @change="cityChanged"
                id="perPageSelect"
                v-model="limit"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="offset-3">
            <b-pagination
              @change="paginate"
              class="justify-content-end"
              v-model="page"
              :per-page="limit"
              :total-rows="totalServices"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <CreateServiceModal
      :isBusy="isModalBusy"
      :service="service"
      :show="showCreateServiceModal"
      :action="action"
      @closeServicePopUp="closeServicePopUp"
      @added="addedNew"
      @updated="updatedService"
    />
  </div>
</template>

<script>
import {
  BTable,
  BCardTitle,
  BCardFooter,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BImg,
  VBTooltip,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import axios from "axios";
import CreateServiceModal from "@/components/services/CreateServiceModal.vue";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BImg,
    BFormSelect,
    BFormSelectOption,
    CreateServiceModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [{ key: "index", label: "S.No" }, "name"],
      totalServices: 0,
      showCreateServiceModal: false,
      limit: 10,
      page: 1,
      search: "",
      services: [],
      service: {
        _id: "",
        name: "",
      },
      isBusy: false,
      isModalBusy: false,
      action: "add-new",
      pageOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    closeServicePopUp() {
      this.showCreateServiceModal = false;
      this.resetServiceModal();
    },
    initNewServiceAdd() {
      this.$set(this, "showCreateServiceModal", true);
    },
    resetPagination() {
      this.$set(this, "page", 1);
      this.$set(this, "search", "");
    },
    resetServiceModal() {
      this.$set(this, "service", {
        _id: "",
        name: null,
      });
      this.$set(this, "action", "add-new");
    },
    addedNew() {
      this.$set(this, "showCreateServiceModal", false);
      this.resetPagination();
      this.resetServiceModal();
      this.getServices();
    },
    updatedService() {
      this.$set(this, "showCreateServiceModal", false);
      this.resetServiceModal();
      this.getServices();
    },
    getServices() {
      this.isBusy = true;
      let token = this.$getUserToken();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/services?limit=${this.limit}&page=${this.page}&search=${this.search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.services = response.data.services;
          this.totalServices = response.data.totalServices;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error.response);
        });
    },
    onRowClicked(item) {
      this.service = item;
      this.action = "edit";
      this.showCreateServiceModal = true;
    },
    checkEmptySearchInput(value) {
      if (!value) {
        this.resetPagination();
        this.getServices();
      }
    },
    cityChanged() {
      this.$set(this, "page", 1);
      this.getServices();
    },
    paginate(value) {
      this.$set(this, "page", value);
      this.getServices();
    },
    searchServices() {
      this.$set(this, "page", 1);
      this.getServices();
    },
  },
  mounted() {
    this.getServices();
  },
};
</script>
